<template>
  <TTView>
    <VRow>
      <VCol>
        <EmailForm
          :entity="email"
          @update:email="email.email = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_EMAIL } from '../../components/authentication/common';
import EmailForm from '../../components/authentication/EmailForm.vue';

export default {
  name: 'EmailsCreate',

  components: {
    EmailForm,
  },

  data() {
    return {
      loading: false,
      email: { ...DEFAULT_EMAIL },
    };
  },

  methods: {
    async handleSubmit() {
      try {
        this.loading = true;
        const { email } = this;
        email.id = this.$route.params.userId;
        await this.$di.api.Authentication.NewEmailWithoutVerification(email);
        this.$di.notify.snackSuccess('E-mail добавлен');
        this.$router.go(-1);
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
/* stylelint-disable no-empty-source */
</style>
